import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Router, Route, Redirect } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from '../store';
import LandingHeader from './LandingHeader/LandingHeader';
import history from '../history';
// Pages Import
import HomePage from '../pages/home'
// import Dashboard from '../pages/Dashboard/dashboard.js'
import Login from '../pages/login'
import Signup from '../pages/signup'
import AuthLayout from "../layouts/Auth.jsx"
import AdminLayout from "../layouts/Admin.jsx";
import Modals from '../components/Modals/Modals'
// End of Pages Import
// Start Material Design Imports
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


// Start of Utils Imports
import themeObject from '../utils/theme'
import SignUp from '../pages/signup';

const theme = createMuiTheme(themeObject)

const App = (props) => {
  const dispatch = useDispatch()
  const applyMobileMenu = useSelector(state => state.headerConfig)
  const user = JSON.parse(localStorage.getItem('user'))
  dispatch({ type: 'SET_USER_CREDENTIALS', payload: user })
  const redirect = () => {
    if (localStorage.getItem('FBIdToken')) {
      return true
    } else {
      return <Redirect to="/auth/login" />
    }
  }

  return(
    <PersistGate loading={null} persistor={persistor}>
      <Modals />
      <div className={(applyMobileMenu.mobile ? 'popup-mobile-menu-wrapper' : '') }>
        <Router history={history}>
          {redirect()}
          {/* <Route path="/signup" exact component={Signup} /> */}
          {/* <Route path="/" component={HomePage} exact /> */}
          {/* <Route path="/dashboard" component={Dashboard} exact /> */}
          <Route path="/auth" render={props => <AuthLayout {...props} />} />
          <Route path="/admin" render={props => <AdminLayout {...props} />} />
          <Route from="/" to="/admin/index" />
          <Redirect from="/login" to="/auth/login" />
        </Router>
      </div>
    </PersistGate>
  );
};

const mapStateToProps = (state) => {
  return {
    header: state.headerConfig,
    ui: state.uiConfig
  }
}

export default connect(mapStateToProps)(App);
