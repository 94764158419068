const initialState = {
  sticky: false,
  mobile: false,
  theme: "default",
  classList: 'fac-header-dark'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SIDE_HEADER':
      return action.payload
    case 'SET_THEME': 
      return Object.assign({}, state, {
        theme: action.payload,
        classList: `fac-header-${action.payload}`
      })
    case 'SET_MOBILE_HEADER':
      const newState = action.payload || !state.mobile
      return Object.assign({}, state, {
        mobile: newState
      })
    default:
      return state
  }
}