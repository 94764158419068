import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import Jumbotron from '../components/Jumbotron/Jumbotron';
// import SectionOne from '../components/SectionOne/SectionOne';
import SectionTwo from '../components/SectionTwo/SectionTwo';
// import SectionThree from '../components/SectionThree/SectionThree';
// import SectionFour from '../components/SectionFour/SectionFour';
import SectionFive from '../components/SectionFive/SectionFive';
import CallToAction from '../components/CallToAction/CallToAction';
import HowItWorks from '../components/HowItWorks/HowItWorks';
import Footer from '../components/Footer/Footer';
import ActionButton from '../components/ActionButton/ActionButton';
import imgBf from './bg.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/styles/plugins/plugins.css';
import '../assets/styles/standard/style.css';
import '../assets/styles/style.scss';

const HomePage = () => {
    const style = useSelector(state => state.styleConfig)
    const previousColor = style.background[style.currentBg];
    const newColor = style.background[style.currentBg+(1%3)];
    useEffect(() => {
        const container = document.getElementById("main-content-home");
        const scrollCallBack = window.addEventListener("scroll", ()=> {
          if(window.pageYOffset > 50) {
            container.style.background = newColor;
          }
          else{
            container.classList.remove("hola");
          }
        });
        return () => {
          window.removeEventListener("scroll", scrollCallBack)
        };
      }, []);
  return(
    <div id="home-section" className="template-color-5 template-font-1">
        {/* <!-- Wrapper --> */}
        <div id="wrapper" className="">
            <main id="main-content-home" className="page-content">
                <Jumbotron />
                <HowItWorks />
                <SectionTwo />
                <SectionFive />
                <CallToAction />
            </main>
        </div>
        <Footer />
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      styleConfig: state.styleConfig
    }
  }
  
export default connect(mapStateToProps)(HomePage);
