import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ActionButton from '../ActionButton/ActionButton';
import './style.scss';

const Container = styled.div`
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 42%, rgba(113,0,255,1) 100%); !important;
  align-items: center;
  padding-bottom: 20vh;
`;

class SectionTwo extends React.Component {
  render(){
    return(
      <Container id="about-section">
        <div className="brook-about-area pt--130 pt_md--80 pt_sm--60 rslide bg-shape" data-background="#5e0891">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-content text-center max-width--990">
                            <h6 className="heading heading-h6 font-blod text-white letter-spacing-3">ABOUT US</h6>
                            <div className="bkseparator--10"></div>
                            <h3 className="heading heading-h3 line-height-1-62 text-white font-40">
                              A <span className={this.props.themeMainFontColor}>recruiter journey</span> is full of <span className={this.props.themeMainFontColor}>time-consuming</span>
                               and <span className={this.props.themeMainFontColor}>recurrent</span> tasks</h3>
                              <br/>
                              <h3 className="heading heading-h3 line-height-1-62 text-white font-40">We have suffered for 30 years and we
                              just want to avoid you to get into the same mistakes.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="ocean">
          <div className="wave"></div>
          <div className="wave"></div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    themeMainFontColor: state.styleConfig.themColorCss,
    styleConfig: state.styleConfig
  }
}

export default connect(mapStateToProps)(SectionTwo);