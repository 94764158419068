import React from "react";
import './style.scss';
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

function Header(props){
  return (
    <>
      <div className="header bg-gradient-info pb-7 pt-5 pt-md-7">
        <Container fluid>
          <div className="header-body">
            {props.children}
          </div>
        </Container>
      </div>
    </>
  );
}

export default Header;
