const initialState = {
    currentTab: 1,
    currentNavId: null
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
        case 'INITIALIZE_NAV':
            return {
                ...state,
                currentTab: 1,
                currentNavId: action.payload.currentNavId
            }
        case 'SET_NAVTAB':
            return {
                ...state,
                currentTab: action.payload
            }
      default:
        return state
    }
  } 