const initialState = {
  landingHeader: "visible",
  modal: {
    modalType: '',
    isOpen: false
  },
  offerFilter: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'HIDE_LANDING_HEADER':
      return Object.assign({}, state, {
        landingHeader: "hidden"
      })
      case 'SHOW_LANDING_HEADER':
      return Object.assign({}, state, {
        landingHeader: "visible"
      })
    case 'TOGGLE_MODAL':
     return Object.assign({}, state, {
       modal: {
        modalType: action.payload.type,
        isOpen: !state.modal.isOpen,
        header: action.payload.header || null,
        body: action.payload.body || null
       }
     })
    default:
      return state
  }
}