import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ActionButton from '../ActionButton/ActionButton';
import './style.scss';

const Container = styled.div`

`;

class Footer extends React.Component {
  render(){
    return(
      <Container id="contact-section">
        <footer className="page-footer bg_color--3 pl--150 pr--150 pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--5 pr_sm--5">
            <div className="bk-footer-inner pt--150 pb--30 pt_sm--100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="footer-widget text-var--2">
                                <div className="logo">
                                    <a href="index.html">
                                        <img src={process.env.PUBLIC_URL + '/logo-positive.svg'} alt="brook white" />
                                    </a>
                                </div>
                                <div className="footer-inner">
                                    <p>HR e-volution was born in NYC. We are trying to bring innovation and
                                    agility to every company's HR department. We put the focus on these repetitive
                                    tasks during the recruitment journey.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_mobile--40">
                            <div className="footer-widget text-var--2 menu--about">
                                <h2 className="widgettitle">About us</h2>
                                <div className="footer-menu">
                                    <ul className="ft-menu-list bk-hover">
                                        <li><a href="about.html">About Us</a></li>
                                        <li><a href="team.html">Team</a></li>
                                        <li><a href="#">Career</a></li>
                                        <li><a href="service.html">Services</a></li>
                                        <li><a href="contact.html">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                            <div className="footer-widget text-var--2 menu--contact">
                                <h2 className="widgettitle">Contact</h2>
                                <div className="footer-address">
                                    <div className="bk-hover">
                                        <p>2187 Frederick Douglas Blvd
                                        <br />New York, NY 10026</p>
                                        <p><a href="#">hr.evolution.now@gmail.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="copyright ptb--50 text-var-2">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="copyright-left text-md-left text-center">
                                <ul className="bk-copyright-menu d-flex bk-hover justify-content-center justify-content-md-start flex-wrap flex-sm-nowrap">
                                    <li><a href="#">Contact us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="copyright-right text-md-right text-center">
                                <p>© 2019 HR e-evolution. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    themeMainFontColor: state.styleConfig.themColorCss,
    styleConfig: state.styleConfig
  }
}

export default connect(mapStateToProps)(Footer);