import React from "react";
import { connect } from 'react-redux'
// ACTIONS
import { toggleModal } from '../../actions/uiActions'
// reactstrap components
import {
  Button,
  Modal,
  Row,
  Col
} from "reactstrap";

class Modals extends React.Component {
  debugger
  toggleModal = () => {
    this.props.toggelModal("")
  };
  render() {
    return (
      <>
        <Row>
          <Col md="4">
            <Modal
              className={`modal-dialog-centered modal-${this.props.ui.modal.modalType}`}
              contentClassName={`bg-gradient-${this.props.ui.modal.modalType}`}
              isOpen={this.props.ui.modal.isOpen}
            >
              <div className="modal-header">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.props.toggleModal("")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="py-3 text-center">
                  <i className="ni ni-bell-55 ni-3x" />
                  <h4 className="heading mt-4">{this.props.ui.modal.header}</h4>
                  <p>
                    {this.props.ui.modal.body}
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <Button className="btn-white" color="default" type="button" onClick={() => this.props.toggleModal("")}>
                  Okey Dockey!
                </Button>
                <Button
                  className="text-white ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.props.toggleModal("")}
                >
                  Cerrar
                </Button>
              </div>
            </Modal>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ui: state.uiConfig
  }
}

export default connect(
  mapStateToProps,
  {toggleModal})(Modals)