import React from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import styled from 'styled-components';

const Button = styled.button`
  /* Adapt the colors based on primary prop */

  color: ${props => props.primary ? "white" : "palevioletred"};

  margin: 1em;
  padding: 0.25em 1em;
`;

const ActionButton = ({ primary, text, redirectTo }) => {
  const dispatch = useDispatch();
  const sectionConfig = useSelector(state => state.sectionConfig)
  const linkTo = {redirectTo}
  return (
    <div>
      <Button className="btn-lg fac-btn text-white space-between bk-btn-white-border " primary>
        <a onClick={() => dispatch({ type: 'SET_SECTION', payload: `#${redirectTo}`})} href={sectionConfig.currentSection}>
          {text}
        </a>
      </Button>
   </div>   
  );
}


export default connect()(ActionButton)