import api from '../api/'
import { async } from 'q';
import axios from 'axios';
import history from '../history';

export const setApplicantToUser = (dataIn) => {
  return async function(dispatch, getState){
    let response
    const data = {
      applicationStatus: dataIn.status,
      offerId: getState().offer.selectedOffer.id,
      offerAward: getState().offer.selectedOffer.data.award || null,
      offerDescription: getState().offer.selectedOffer.data.description || null,
      offerId: getState().offer.selectedOffer.id || null,
      candidateUserId: null,
      candidateEmail: dataIn.email || null,
      candidateName: dataIn.name || null,
      candidateReasoning: dataIn.reasoning || null,
      recruiterUserId: getState().user.credentials.userId
    }
    response = await api.post(`/addApplicationToUser`, data)
    if(response.status === 202){
      dispatch({ type: 'TOGGLE_MODAL', payload: {
        type: "danger",
        header: "Recomendación enviada",
        body: "Se ha añadido la aplicación correctamente"}})
    }
    if(response.status === 201){
      dispatch({ type: 'TOGGLE_MODAL', payload: {
        type: "success",
        header: "Recomendación enviada",
        body: "Se ha añadido la aplicación correctamente"}})
    }
  }
}

export const getUserApplications = () => {
  return async function(dispatch, getState){
    let data = {
      userId: getState().user.credentials.userId
    } 
    const response = await api.post('/getUserApplication', data)
    if(response.status === 201){
      dispatch({ type: 'SET_RECRUITER_APPLICATIONS', payload: [] })
    }else{
      dispatch({ type: 'SET_RECRUITER_APPLICATIONS', payload: response.data })
    }
  }
}

export const getCandidateApplications = () => {
  return async function(dispatch, getState){
    let data = {
      candidateUserId: getState().user.credentials.userId
    } 
    const response = await api.post('/getCandidateApplications', data)
    if(response.status === 201){
      dispatch({ type: 'SET_CANDIDATE_APPLICATIONS', payload: [] })
    }else{
      dispatch({ type: 'SET_CANDIDATE_APPLICATIONS', payload: response.data })
    }
  }  
}

export const setFollowedApplication = (dataIn) => {
  return async function(dispatch, getState){
    let response
    const data = {
      applicationStatus: "follow",
      offerId: getState().offer.selectedOffer.id,
      offerAward: getState().offer.selectedOffer.data.award || null,
      offerDescription: getState().offer.selectedOffer.data.description || null,
      offerId: getState().offer.selectedOffer.id || null,
      candidateUserId: null,
      candidateEmail: dataIn.email || null,
      candidateName: dataIn.name || null,
      candidateReasoning: dataIn.reasoning || null,
      recruiterUserId: getState().user.credentials.userId
    }
    response = await api.post(`/followApplication`, data)
    if(response.status === 202){
      dispatch({ type: 'TOGGLE_MODAL', payload: {
        type: "danger",
        header: "Imposible",
        body: "Ya estás siguiendo o has recomendado esta oferta de trabajo"}})
    }
    if(response.status === 201){
      dispatch({ type: 'TOGGLE_MODAL', payload: {
        type: "success",
        header: "Hecho",
        body: "La hemos añadido a tu dasboard"}})
    }
  }
}
export const getFollowedApplications = () => {
  return async function(dispatch, getState){
    let data = {
      userId: getState().user.credentials.userId
    } 
    const response = await api.post('/getFollowedApplications', data)
    if(response.status === 201){
      dispatch({ type: 'SET_FOLLOWED_APPLICATIONS', payload: [] })
    }else{
      dispatch({ type: 'SET_FOLLOWED_APPLICATIONS', payload: response.data })
    }
  }    
}


export const getUserReferencesByEmail = () => {
  return async function(dispatch, getState){
    let data = {
      email: getState().user.credentials.email
    }
    const response = await api.get('/getUserReferencesByEmail', data)
    if(response.status === 200){
      dispatch({ type: 'SET_FOLLOWED_APPLICATIONS', payload: response.data })
    }else{
      dispatch({ type: 'SET_FOLLOWED_APPLICATIONS', payload: [] })   
    }
  }
}

export const sendApplicationEmail = (dataIn) => {
  return async function(dispatch, getState){
    const emailFrom = {
      name: getState().user.credentials.firstName,
      email: getState().user.credentials.email,
      subject: `Recomendación en camino`,
      from: `hola@flowtu.com`,
      templateId: 'd-eb44d4259759473f81b6b59178b2da6d'
    }
    const emailTo = {
      name: dataIn.name,
      email: dataIn.email,
      from: `hola@flowtu.com`,
      templateId: 'd-94c8a7b733534360ba02647d398a32d9'
    }
    // api.post(`/sendEmail`, emailFrom)
    // api.post(`/sendEmail`, emailTo)
    dispatch(setApplicantToUser(dataIn))
  }
}