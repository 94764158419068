import React from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from 'react-redux';
// reactstrap components
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Col
} from "reactstrap";

const NavTab = (props) => {
    const navTab = useSelector(state => state.navTab)
    const dispatch = useDispatch();
    const toggleNavs = (e, tabId) => {
        e.preventDefault();
        dispatch({ type: 'SET_NAVTAB', payload: tabId })
      };
    return(
        <div className="nav-wrapper">
          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            {
                props.tabs.map((tab, key) => {
                    return(
                        <Col xl="2" l="1">
                            <NavItem>
                                <NavLink
                                    aria-selected={tab.id === navTab.currentTab}
                                    className={classnames("mb-sm-3 mb-md-1", {
                                        active: tab.id === navTab.currentTab
                                    })}
                                    onClick={
                                        e => toggleNavs(e, tab.id)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <i className="ni ni-cloud-upload-96 mr-2" />
                                    {tab.tabName}
                                </NavLink>
                            </NavItem> 
                        </Col>
                    )               
                })
            }
          </Nav>
        </div>
    )
}

export default NavTab;