import React from 'react';
import { connect } from 'react-redux';
import imgBackground from './humans.svg';
import styled from 'styled-components';
import ActionButton from '../ActionButton/ActionButton';
import FormAlert from '../FormAlert/FormAlert';
import { setNewSection } from '../../actions/'

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import './style.scss';

const Container = styled.div`
  position: relative;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 42%, rgba(113,0,255,1) 100%); !important;
  align-items: center;
  padding-top: 4em !important;
  display: flex;
  align-items: center
`;

class Jumbotron extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      classes: null
    }
  }

  componentDidMount() {
    const useStyles = makeStyles(theme => ({
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
      },
    }))

    this.setState({classes: useStyles.classes})
  }


  render(){
    return(
      <Container className="jumbotron center aligned slider-revoluation">
        <div className="test tp-caption tp-resizeme fac-img-wrapper">
          <div className="fac-img-container">
            <img src={process.env.PUBLIC_URL + 'imgs/jumbo_back_1.svg'} alt="brook white" />
          </div>
        </div>
        <div className="container fac-big-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-caption layer1">
                <h1 className="text-white">Recomend a friend to a company's open position</h1>
              </div>
              <div className="tp-caption startups-pra layer2">
                <h2>Get up to 5000$ per recommendation</h2>
                <h2>Recommend well. Get ranked and become the best head hunter</h2>
              </div>
              <div className="btn-group btn-group-lg" role="group">
                <ActionButton text="How do we do it?" primary={false} redirectTo="how-it-works-section"/>
                <FormAlert text="Do you want a trial?" primary={false}/>              
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    props: state,
    section: state.sectionConfig
  }
}

export default connect(mapStateToProps, { setNewSection })(Jumbotron);