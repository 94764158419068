import { combineReducers } from 'redux';
import applicationReducer from './applicationReducer';
import headerReducer from './headerReducer';
import navTabReducer from './navTabReducer';
import styleConfigReducer from './styleConfigReducer';
import sectionConfigReducer from './sectionConfigReducer';
import subscribedUserReducer from './subscribedUserReducer';
import userReducer from './userReducer'
import uiReducer from './uiReducer'
import offerReducer from './offerReducer';

export default combineReducers({
  application: applicationReducer,
  headerConfig: headerReducer,
  navTab: navTabReducer,
  offer: offerReducer,
  styleConfig: styleConfigReducer,
  sectionConfig: sectionConfigReducer,
  subscribedUser: subscribedUserReducer,
  uiConfig: uiReducer,
  user: userReducer
});