import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import NavTab from "../components/Navbars/NavTab";
import { getUserApplications, getCandidateApplications, getFollowedApplications } from '../actions/applicationActions'
// node.js library that concatenates classes (strings)

import Countdown from 'react-countdown-now'
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import Header from "../components/Headers/Header.jsx";

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeNav: 1
    }
  }

  async componentWillMount() {
    await this.props.getUserApplications()
    await this.props.getCandidateApplications()
    await this.props.getFollowedApplications()
  }

  dateToString(date) {
    let newDate = new Date(date)
    let stringDate = new String()
    stringDate = newDate.getDate() + "-" + (newDate.getMonth()+1) + "-" + newDate.getFullYear()
    return stringDate
  }

  expireDate(date) {
    let newDate = new Date(date)
    let stringDate = new String()
    stringDate = newDate.getDate() + "-" + (newDate.getMonth()+1) + "-" + newDate.getFullYear()
    return stringDate   
  }

  returnNameOrEmail(application) {
    return application.candidateName || application.candidateEmail || null
  }

  render() {
    const Completionist = () => <span>Ha expirado</span>
    const data = this.props.recruiterApplications
    const tabs = [
      {
        id: 1,
        tabName: "Recruiter"
      },
      {
        id: 2,
        tabName: "Candidato"
      }
    ]
    return (
      <>
        <Header />
        {/* Page content */}
        <Container fluid>
          <NavTab tabs={tabs}/>
          {this.props.navTab.currentTab === 1 &&
            <div tabId="1">
            <Row className="mt-3">
            <Col className="mb-5 mb-xl-0" l="12" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Mis referencias</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Posición</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Ganancia</th>
                      <th scope="col">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.props.recruiterApplications.map((data, key) => {
                        if (data.applicationStatus !== 'follow') {
                          return (
                            <tr>
                              <th scope="row">
                                {this.returnNameOrEmail(data)}
                              </th>
                              <td>{data.offerDescription}</td>
                              <td>{this.dateToString(data.createdAt)}</td>
                              <td>{data.offerAward}</td>
                              <td>{data.applicationStatus}</td>
                            </tr>
                          )                         
                        }
                      })
                    }
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col l="12" xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Siguiendo</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Posición</th>
                      <th scope="col">Recompensa</th>
                      <th scope="col">Expira</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        this.props.recruiterApplications.map((data, key) => {
                          if (data.applicationStatus === "follow") {
                            return (
                              <tr>
                                <td>{data.offerDescription}</td>
                                <td>{data.offerAward}</td>
                                <td>
                                  <Countdown date={new Date(this.dateToString(data.createdAt))}>
                                    <Completionist />
                                  </Countdown>
                                </td>
                              </tr>
                            )                         
                          }
                        })
                      }
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          </div>
          }
          {this.props.navTab.currentTab === 2 &&
            <div tabId="2">
            <Row className="mt-3">
            {
                this.props.candidateApplications.map((data, key) => {
                  if (data.candidate === false) {
                    return (
                      <Col className="mb-5 mb-xl-0" l="3" xl="3">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  {`${data.offerDescription}`}
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {`Expira: ${this.dateToString(data.createdAt)}`}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                  <i className="fas fa-chart-pie" />
                                </div>
                              </Col>
                            </Row>
                            <p className="mt-3 mb-0 text-muted text-sm">
                              <span className="text-nowrap text-uppercase text-muted mb-0">
                                {`Estado: ${data.applicationStatus}`}
                              </span>
                            </p>
                            <CardFooter>
                              <div className="d-flex justify-content-between">
                                <Button
                                  color="success"
                                  size="m"
                                >
                                  Detalles
                                </Button>
                                <Link
                                >
                                  <Button
                                    color="info"
                                    size="m"
                                  >
                                    Parar
                                  </Button>
                                </Link>
                              </div>
                            </CardFooter>
                          </CardBody>
                        </Card>
                      </Col>
                    )                         
                  }
                })
              }
          </Row>
          </div>
          }
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    recruiterApplications: state.application.recruiterApplications,
    candidateApplications: state.application.candidateApplications,
    followingApplications: state.application.followingApplications,
    navTab: state.navTab
  }
}

export default connect(mapStateToProps,
  {getUserApplications, getCandidateApplications, getFollowedApplications})
  (Index)

