import api from '../api/'
import { async } from 'q';
import axios from 'axios';
import history from '../history';


export const loginUser = (data, history) => async (dispatch) => {
  const response = await api.post('/login', data)
  if(response.status === 200){
    localStorage.setItem('FBIdToken', `Baarer ${response.data.stsTokenManager.accessToken}`)
    await dispatch(getUserData(response.data))
    history.push('/admin/index')
    dispatch({ type: 'HIDE_LANDING_HEADER' })
  }else{
    console.log({message: "error when login"})
  }
}

export const registerUser = (data, history) => (dispatch) => {
  api.post('/register', data)
    .then((res) => {
      localStorage.clear()
      localStorage.setItem('FBIdToken', `Baarer ${res.data.token}`)
      let userData = {
        email: data.email,
        firstName: data.name,
        userId: res.data.userId
      }
      dispatch({ type: 'SET_USER_CREDENTIALS', payload: userData })
      history.push('/admin/index')
    }).catch(err => {
      console.log({message: "error when login", err})
    })
}


export const getUserData = (userData) => async(dispatch) => {
  // TODO fix axios.defaults to set headers
  // axios.defaults.common['Authorization'] = `Baarer ${res.data.stsTokenManager.accessToken}`
  const response = await api.get(`/users?userId=${userData.uid}`, {
    headers: {
      'Authorization': `Baarer ${userData.stsTokenManager.accessToken}`
    }
  })
  if(response.status === 200) {
    dispatch({ type: 'SET_USER_CREDENTIALS', payload: response.data.credentials })
  }
  else{
    return false
  }
}