import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ActionButton from '../ActionButton/ActionButton';
import './style.scss';

const Container = styled.div`
  background-color: white;
`;

class HowItWorks extends React.Component {
  render(){
    return(
      <Container className="howitworks center aligned slider-revoluation" id="how-it-works-section">
        <div className="case-studis-area pt--120 pt-md--80 pt-sm--60 pb--120 ">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="brook-section-title text-center mb--45">
                            <h2 className="heading-40 fw-200">How it works?</h2>
                        </div>
                    </div>
                </div>
                <div className="brook-element-carousel slick-arrow-center">
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 align-self-center">
                      <div className="case-slider-content">
                          <div className="post-count">
                              01.
                          </div>
                          <h6 className="heading headin-h6 theme-color wow move-up animate">Sourcing</h6>
                          <h3 className="heading-font line-height-1-5 wow move-up animate">Look for open hiring processes</h3>
                          <p className="text">Choose these you feel like you have someone who can fit the companies' requeriments</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-6">
                          <div className="image-wrap">
                              <div className="image">
                                  <div className="image">
                                    <img src={process.env.PUBLIC_URL + 'imgs/we_are_hiring.svg'} alt="we are hiring" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 align-self-center">
                      <div className="case-slider-content">
                        <div className="post-count">
                            02. 
                        </div>
                        <h6 className="heading headin-h6 theme-color wow move-up animate">Application</h6>
                        <h3 className="heading-font line-height-1-5 wow move-up animate">Apply to the positions</h3>
                        <p className="text">Send you application meeting the company's needs</p>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                      <div className="image-wrap">
                        <div className="image">
                          <div className="image">
                          <img src={process.env.PUBLIC_URL + 'imgs/application.svg'} alt="job application" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 align-self-center">
                      <div className="case-slider-content">
                        <div className="post-count">
                          03. 
                        </div>
                          <h6 className="heading headin-h6 theme-color wow move-up animate">Monetization</h6>
                          <h3 className="heading-font line-height-1-5 wow move-up animate">Get ranked and paid</h3>
                          <p className="text">Based on your ranking, companies will pay more for your recommendation</p>
                        </div>
                      </div>
                    <div className="col-xl-7 col-lg-6">
                      <div className="image-wrap">
                        <div className="image">
                          <div className="image">
                            <img src={process.env.PUBLIC_URL + 'imgs/get_rated_money.svg'} alt="get paid and reated" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    props: state
  }
}

export default connect(mapStateToProps)(HowItWorks);