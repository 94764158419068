import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
const initialState = {
  selected: {},
  recruiterApplications: [],
  candidateApplications: [],
  followingApplications: []
}

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RECRUITER_APPLICATIONS':
    return {
        ...state,
        recruiterApplications: action.payload
      }
    case 'SET_CANDIDATE_APPLICATIONS':
    return {
        ...state,
        candidateApplications: action.payload
      }
    case 'SET_FOLLOWED_APPLICATIONS':
    return {
        ...state,
        followingApplications: action.payload
      }
    case 'SET_SELECTED_APPLICATION':{
      return state
    }
    case 'FOLLOW_OFFER':
      const found = state.recruiterApplications.findIndex(application => application.id === action.payload.id)
     return
    default:
      return state
  }
}

const persistConfig = {
  key: 'application',
  storage: storage
};

export default persistReducer(persistConfig, applicationReducer);