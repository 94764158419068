import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { setSubscribedUser } from '../../actions'
import './style.scss';

const Container = styled.div`

`;

class CallToAction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {email: this.props.subscribedUser.email};

        this.handleChange = this.handleChange.bind(this);
      }

      handleChange(event) {
        this.setState({email: event.target.value});
      }

    render(){
    return(
      <Container id="about-section">
        <div className="brook-call-to-action bg_color--13 ptb--70 bg-as-text2">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-12 col-12">
                        <div className="call-content text-center text-md-left text-center">
                            <h4 className="heading heading-h4 text-white">Are you interested?</h4>
                            <em>We will only contact you once we are ready to start. No commercial use</em>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12">
                        <div className="text-center text-md-right text-center mt_sm--30 mt_md--40 contact-form contact-form--4 yellow-color-2">
                            <form>
                                <div className="input-box">
                                    <input onChange = { this.handleChange } type="email" placeholder="Your e-mail" value={ this.state.email }/>
                                    <button onClick={() => this.props.setSubscribedUser(this.state.email)}>
                                        Yes, I am
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    themeMainFontColor: state.styleConfig.themColorCss,
    styleConfig: state.styleConfig,
    subscribedUser: state.subscribedUser
  }
}

export default connect(mapStateToProps, { setSubscribedUser })(CallToAction);