import React, { useEffect } from 'react';
import { withRouter, Link, BrowserRouter as Router } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import './style.scss';
import { Button, ButtonGroup } from '@material-ui/core';

const HeaderContainer = styled.div`
    visibility: ${props => props.visibility}
`;
// TODO: Make theme and class list in reducer to set up by default depending on component URL
const LandingHeader = (props) => { 
    const dispatch = useDispatch();
    const sectionConfig = useSelector(state => state.sectionConfig)
    const headerConfig = useSelector(state => state.headerConfig)
    const uiConfig = useSelector(state => state.uiConfig)
    const location = props.location.pathname
    useEffect(() => {
        if(location == "/dashboard"){
            {dispatch({type:'HIDE_LANDING_HEADER'})}
        } else{
            {dispatch({type:'SHOW_LANDING_HEADER'})}
        }
        const header = document.getElementById("myHeader");
        const scrollCallBack = window.addEventListener("scroll", ()=> {
        if(window.pageYOffset > 50) {
            header.classList.add("headroom--sticky","headroom--not-top","headroom--unpinned");
        }
        else{
            header.classList.remove("headroom--sticky","headroom--not-top","headroom--unpinned");
        }
        });
        return () => {
        window.removeEventListener("scroll", scrollCallBack)
        };
  }, [location]);

  return(
    <HeaderContainer className="fac-header" visibility={uiConfig.landingHeader}>
        <header
            id="myHeader"
            className={
                `fac-header ${headerConfig.classList}
                 br_header header-default header-transparent light-logo--version haeder-fixed-width header-mega-menu clearfix headroom headroom--not-bottom headroom--pinned headroom--top`
            }>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="header__wrapper mr--0">
                            <div className="header-left">
                                <div className="logo">
                                    <a
                                        onClick={
                                            () => [
                                                dispatch({ type: 'SET_SECTION', payload: "#home-section" })
                                            ]
                                        }
                                        href={sectionConfig.currentSection}>
                                    >
                                        <img className="logo" src={process.env.PUBLIC_URL + '/logo_white.svg'} />
                                    </a>
                                </div>
                            </div>
                            <div className="mainmenu-wrapper d-none d-lg-block">
                                <nav className="page_nav">
                                    <ul className="mainmenu">
                                        <li className="lavel-1 slide--megamenu">

                                                <a
                                                    onClick={
                                                        () => [
                                                            dispatch({ type: 'SET_SECTION', payload: "#home-section" })
                                                            ]
                                                        }
                                                    href={`/${sectionConfig.currentSection}`}>
                                                    <span>Home</span>
                                                </a>
                                        </li>
                                        <li className="lavel-1 slide--megamenu">
                                            <a
                                                onClick={
                                                    () => [
                                                        dispatch({ type: 'SET_SECTION', payload: "#how-it-works-section" })
                                                        ]
                                                    }
                                                href={`/${sectionConfig.currentSection}`}>
                                                <span>How it works</span>
                                            </a>
                                        </li>
                                        <li className="lavel-1 slide--megamenu">
                                            <a
                                                onClick={() => [
                                                    dispatch({ type: 'SET_SECTION', payload: "#about-section" })
                                                    ]
                                                }
                                                href={`/${sectionConfig.currentSection}`}
                                            >
                                                <span>About Us</span>
                                            </a>
                                        </li>
                                        <li className="fac-lavel lavel-1 slide--megamenu">
                                            <a
                                                onClick={() => [
                                                    dispatch({ type: 'SET_SECTION', payload: "#contact-section" })
                                                    ]
                                                }
                                                href={`/${sectionConfig.currentSection}`}
                                            >
                                                <span>Contact</span>
                                            </a>
                                        </li>
                                        <li>
                                            <ButtonGroup>
                                                <Link onClick= {() => dispatch({type: 'SET_THEME', payload: "dark" })} to="/login">
                                                    <Button
                                                        variant="outlined" color="secondary">
                                                        Log in
                                                    </Button>
                                                </Link>
                                                {/* <Button variant="outlined" color="secondary">
                                                    Sign up
                                                </Button> */}
                                            </ButtonGroup>
                                        </li>
                                    </ul>
                                </nav>
                              </div>
                              <div className="header-right">
                                  <div
                                    className="manu-hamber popup-mobile-click light-version d-block d-xl-none"
                                    onClick={() => dispatch({type: 'SET_MOBILE_HEADER' })}
                                  >
                                      <div>
                                          <i></i>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
        </header>
        {/* <!-- Start Popup Menu --> */}
        <div className="popup-mobile-manu popup-mobile-visiable">
            <div className="inner">
                <div className="mobileheader">
                    <div className="logo">
                        <a
                            onClick={() => [
                                dispatch({ type: 'SET_SECTION', payload: "#home-section" }),
                                dispatch({type: 'SET_MOBILE_HEADER', payload: false })
                            ]}
                            href={`/${sectionConfig.currentSection}`}
                        >
                            <img className="logo" src={process.env.PUBLIC_URL + '/logo-negative.svg'} alt=""/>
                        </a>
                    </div>
                    <a className="mobile-close" onClick={() => dispatch({type: 'SET_MOBILE_HEADER' })} hrfw="#"></a>
                </div>
                <div className="menu-content">
                    <ul className="menulist object-custom-menu">
                        <li className="has-no-mega-menu">
                            <a
                                onClick={() => [
                                    dispatch({ type: 'SET_SECTION', payload: "#home-section" }),
                                    dispatch({type: 'SET_MOBILE_HEADER', payload: false })
                                ]}
                                href={`/${sectionConfig.currentSection}`}
                            >                            
                            <span>Home</span></a>
                        </li>
                        <li className="lavel-1 slide--megamenu">
                            <a
                                onClick={
                                    () => [
                                        dispatch({ type: 'SET_SECTION', payload: "#how-it-works-section" }),
                                        dispatch({type: 'SET_MOBILE_HEADER', payload: false })
                                        ]
                                    }
                                href={`/${sectionConfig.currentSection}`}
                            >
                                <span>How it works</span>
                            </a>
                        </li>
                        <li className="has-no-mega-menu">
                            <a
                                onClick={() => [
                                    dispatch({ type: 'SET_SECTION', payload: "#about-section" }),
                                    dispatch({type: 'SET_MOBILE_HEADER', payload: false })
                                ]}
                                href={`/${sectionConfig.currentSection}`}                            
                            ><span>About Us</span>
                        </a>
                        </li>

                        <li className="has-no-mega-menu">
                            <a
                                onClick={() => [
                                    dispatch({ type: 'SET_SECTION', payload: "#contact-section" }),
                                    dispatch({type: 'SET_MOBILE_HEADER', payload: false })
                                ]}
                                href={`/${sectionConfig.currentSection}`}><span>Contact</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        {/* <!-- End Popup Menu --> */}
    </HeaderContainer>
  )
}

export default withRouter(LandingHeader);