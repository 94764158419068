const initialState = {
  background: ["red", "black", "yellow"],
  currentBg: 0,
  themColorCss: "fac-theme-color-pink"
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BACKGROUND_COLOR':
      return action.payload
    default:
      return state
  }
}