import React from "react";
import { connect } from 'react-redux';
import { getOfferDetail, setSelectedOffer, loginUser } from '../../actions'
import OfferDescription from './OfferDescription'
// node.js library that concatenates classes (strings)
import './style.scss';
import classnames from "classnames";
import ApplicationForm from '../../components/ApplicationForm/ApplicationForm'
import Header from "../../components/Headers/Header.jsx";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "../../variables/charts.jsx";

class OfferDetail extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      activeNav: 1
    }
  }
  async componentDidMount() {
    await this.props.getOfferDetail(this.props.selectedOffer.id)
  }
  isRemoteJob(data) {
    if(data){
      return "Sí"
    }
    return "No"
  }
  render() {
    return (
      <>
        <Header>
          <h1>{this.props.selectedOffer.data.name}</h1>
          <div>
            <span className="hr-header-detail-badge mr-2">
              <Badge className="p-2" color="danger">Salario: 2500€ + 3400€</Badge>
            </span>
            <span className="hr-header-detail-badge mr-2">
              <Badge className="p-2" color="danger">Recompensa: {this.props.selectedOffer.data.award}</Badge>
            </span>
            <span className="hr-header-detail-badge mr-2">
              <Badge className="p-2" color="danger">Sector: {this.props.selectedOffer.data.industry}</Badge>
            </span>
            <span className="hr-header-detail-badge mr-2">
              <Badge className="p-2" color="danger">Lugar: {this.props.selectedOffer.data.location}</Badge>
            </span>
          </div>
        </Header>
        {/* Page content */}
        <Container className="hr-job-desc-container pt-6" fluid id="offer-detail">
          <Row>
            <Col lg="7">
              <OfferDescription />
            </Col>
            <Col lg="4">
              <ApplicationForm />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    offers: state.offer.offers,
    selectedOffer: state.offer.selectedOffer
  }
}

export default connect(mapStateToProps,
  {getOfferDetail, setSelectedOffer, loginUser})
  (OfferDetail)
