import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
const initialState = {
  authenticated: false,
  credentials: {
    firstName: '',
    lastName: '',
    location: '',
    level: '',
    stars: null,
    profileImage: '',
    userId: '',
    bio: '',
    website: '',
    email: ''
  }
}

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return Object.assign({}, state, {
        email: (action.payload),
        authenticated: true
      })
    case 'SET_USER_CREDENTIALS':{
      const newState = {
        ...state,
        authenticated: true,
        credentials: action.payload
      }
      localStorage.setItem('user', JSON.stringify(newState.credentials))
      return newState
    }
    case 'UNSET_USER':
      return initialState
    default:
      return state
  }
}

const persistConfig = {
  key: 'user',
  storage: storage
};

export default persistReducer(persistConfig, UserReducer);