import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const initialState = {
  offers: [],
  selectedOffer: {}
}
const OfferReducer =  (state = initialState, action) => {
  switch (action.type) {
    case 'GET_OFFERS':
      return {
        ...state,
        offers: action.payload
      }

      case 'SET_SELECTED_OFFER':
      let found = state.offers.find(element => element.id == action.payload)
        return {
          ...state,
          selectedOffer: found
        }

      case 'GET_SELECTED_OFFER':
        return state

      case 'UPDATE_OFFER':
        let offers = state.offers.map((e, key) => {
          if(e.id === state.selectedOffer.id){
            let details = {}
            Object.assign(details, action.payload)
            e.details = details
            return e
          }
          else
            return e
        })
        return {
          ...state,
          offers: offers
        }
    default:
      return state
  }
}

const persistConfig = {
  key: 'offer',
  storage: storage
};

export default persistReducer(persistConfig, OfferReducer);