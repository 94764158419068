const initialState = {
  email: ""
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SUBSCRIBE_USER':
      return Object.assign({}, state, {
        email: (action.payload)
      })
    default:
      return state
  }
}