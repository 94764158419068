const initialState = {
  sectionIdRefs: {
    sectionOne: "",
    sectionTwo: "about-us-section",
    sectionThree: "contact-section"
  },
  currentSection: "#home-section"
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SECTION':
      return Object.assign({}, state, {
        currentSection: action.payload
      })
    default:
      return state
  }
} 