import React from "react";
import { connect } from 'react-redux';
import { sendApplicationEmail } from '../../actions/applicationActions'
import './style.scss'

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class ApplicationForm extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      name: '',
      email: '',
      reasoning: '',
      status: 'enviada'
    }
  }

  render() {
    return (
      <>
        <Form onSubmit={(e) =>{
            e.preventDefault()
            this.props.sendApplicationEmail(this.state)
          }}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Input
                  id="application-form-name"
                  placeholder="Nombre"
                  type="text"
                  value={this.state.name}
                  onChange = {(e) => {
                    this.setState({name: e.target.value})
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Input
                  id="application-form-email"
                  placeholder="recomiendo-a@example.com"
                  type="email"
                  value={this.state.email}
                  onChange = {(e) => {
                    this.setState({email: e.target.value})
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Input
              id="application-form-explanation"
              placeholder="En pocas palabras. ¿Por qué es un buen candidato?"
              rows="4"
              type="textarea"
              value={this.state.reasoning}
              onChange = {(e) => {
                this.setState({reasoning: e.target.value})
              }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <Button color="success" sixe="lg" type="submit" outline>
                Recomendar
              </Button>
              {/* <span class="info-span">
                Solo se permite una recomendación
              </span> */}
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    offers: state.offer.offers,
    selectedOffer: state.offer.selectedOffer
  }
}

export default connect(mapStateToProps,
  {sendApplicationEmail})
  (ApplicationForm)