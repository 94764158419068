import React from "react";
import { connect } from 'react-redux';
import { getOffers, setSelectedOffer } from '../../actions'
import { setApplicantToUser, setFollowedApplication } from '../../actions/applicationActions'
import { toggleModal } from '../../actions/uiActions'
import Modals from '../../components/Modals/Modals'
// node.js library that concatenates classes (strings)
import './style.scss'
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "../../variables/charts.jsx";

import Header from "../../components/Headers/Header.jsx";

class Offers extends React.Component {
  state = {
    activeNav: 1,
    filter: this.props.filter
  };

  componentDidMount() {
    const offers = this.props.offer
    this.props.getOffers()
  }
  isRemoteJob(data) {
    if(data){
      return "Sí"
    }
    return "No"
  }

  followOffer (data) {
    this.props.setFollowedApplication(data)
  }
  
  setFilter (f) {
    this.setState({
      filter: f.toUpperCase()
    })
  }

  clearFilter (f) {
    this.setState({
      filter: null
    })
  }

  render() {
    return (
      <>
        <Header>
          <Container fluid id="filter">
            <h2>Filtra por sector</h2>
              <Row>               
                <Card className="header-filter-card card-stats mb-4 mb-xl-0" onClick={(e) => {
                          e.preventDefault()
                          this.setFilter("it")}}>
                  <CardBody>
                    <Row>
                      <div className="col filter-button">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          IT
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon-sm icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-laptop" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="header-filter-card card-stats mb-4 mb-xl-0"  onClick={(e) => {
                          e.preventDefault()
                          this.setFilter("retail")}}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Retail
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon-sm icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="header-filter-card card-stats mb-4 mb-xl-0" onClick={(e) => {
                          e.preventDefault()
                          this.clearFilter()}}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Quitar Filtro
                        </CardTitle>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Row>
          </Container>
        </Header>
        <Container className="mt-3" fluid>
          <Modals>
          </Modals>
          <Row>
            {
              this.props.offers.map((data, key) => {
                if (this.state.filter === null || data.data.industry.toUpperCase() === this.state.filter) {
                  return (
                    <Col lg="12" xl="6" key={data.id}>
                      <Card className="card-stats mb-4 mb-xl-0" >
                        <Link
                          to={`${this.props.match.url}/offer-detail`}
                          onClick={(e) => {
                            this.props.setSelectedOffer(data)
                          }}>
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  {`${data.data.name} -  ${data.data.location}`}
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {`Recompensa: ${data.data.award} €`}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                  <i className="fas fa-chart-pie" />
                                </div>
                              </Col>
                            </Row>
                            <p className="mt-3 mb-0 text-muted text-sm">
                              <span className="text-nowrap text-uppercase text-muted mb-0">
                                {`empresa: ${data.data.company}`}
                              </span>
                            </p>
                            <p className="mt-3 mb-0 text-muted text-sm">
                              <span className="text-nowrap text-uppercase text-muted mb-0">
                                {`remoto: ${data.data.remote===true ? "SÍ" : "NO" }`}
                              </span>
                            </p>
                            <p className="mt-3 mb-0 text-muted text-sm">
                              {data.data.description}
                            </p>
                          <CardFooter>
                            <div className="d-flex justify-content-between">
                              <Button
                                color="success"
                                onClick={e => { 
                                    e.preventDefault()
                                    // this.props.setSelectedOffer(data)
                                    this.followOffer(data)
                                  }
                                }
                                size="m"
                              >
                                Seguir
                              </Button>
                              {/* <Link
                                to={`${this.props.match.url}/offer-detail`}
                                onClick={(e) => {
                                  this.props.setSelectedOffer(data)
                                }}
                              >
                                <Button
                                  color="info"
                                  size="m"
                                >
                                  Detalles
                                </Button>
                              </Link> */}
                            </div>
                          </CardFooter>
                          </CardBody>
                        </Link>
                      </Card>
                    </Col>
                  )                  
                }
              })
            }
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    offers: state.offer.offers,
    ui: state.uiConfig,
    filter: state.uiConfig.offerFilter
  }
}

export default connect(mapStateToProps,
  {getOffers, setSelectedOffer, setApplicantToUser, setFollowedApplication, toggleModal})
  (Offers)
