import React from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import styled from 'styled-components';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const Button = styled.button`
  /* Adapt the colors based on primary prop */

  color: ${props => props.primary ? "white" : "palevioletred"};

  margin: 1em;
  padding: 0.25em 1em;
`;

const ActionButton = ({ primary, text, redirectTo }) => {
  const dispatch = useDispatch();
  const sectionConfig = useSelector(state => state.sectionConfig)

  const [open, setOpen, textValue] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (e) => { 
    setOpen(false)
    dispatch({ type: 'SET_SUBSCRIBE_USER', payload: "sdfsdfdsf"})
  }

  const setTextValue = (e) => {
    setTextValue(e.target.value)
  }

  return (
    <div>
      <Button className="btn-lg fac-btn text-white space-between bk-btn-white-border " primary onClick={handleClickOpen}>
        <a onClick={() => dispatch({ type: 'SET_SECTION', payload: `#${redirectTo}`})} href={sectionConfig.currentSection}>
          {text}
        </a>
      </Button>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Get in touch</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We are not using your email for commercial puposes. We will reach you with with opening positions.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            placeholder="You email"
            onChange = {setTextValue}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
   </div>   
  );
}


export default connect()(ActionButton)