import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ActionButton from '../ActionButton/ActionButton';
import './style.scss';

const Container = styled.div`

`;

class SectionFive extends React.Component {
  render(){
    return(
        <Container id="about-section">
            <div className="brook-stratagy-area bg_color--7">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="bk-stratagy-content maxWidth--600 float-right pt--120 pb--120 pr_lg--30 pl_lg--30 pr_md--50 pl_md--50 pr_sm--30 pl_sm--30">
                            <div className="content">
                                <h3 className="heading heading-h3 line-height-1-42 yellow-color-2 wow move-up text-white">Recursos Humanos como base</h3>
                                <div className="bkseparator--30"></div>
                                <p className="bk_pra font-16 line-height-1-87 pr--30 wow move-up text-white">Nuestra calidad en el servicio se basa en que creemos en los valores de las personas y empresas.</p>
                                <div className="bkseparator--40"></div>

                                <div className="bk-list--2 wow move-up">
                                    <div className="list-header with-ckeck check-yellow-color-2">
                                        <div className="marker"></div>
                                        <div className="title-wrap">
                                            <h5 className="heading heading-h5 text-white">Business Architecture</h5>
                                        </div>
                                    </div>
                                    <div className="list-header with-ckeck check-yellow-color-2">
                                        <div className="marker"></div>
                                        <div className="title-wrap">
                                            <h5 className="heading heading-h5 text-white">Cognitive Architecture</h5>
                                        </div>
                                    </div>
                                    <div className="list-header with-ckeck check-yellow-color-2">
                                        <div className="marker"></div>
                                        <div className="title-wrap">
                                            <h5 className="heading heading-h5 text-white">Interior Architecture</h5>
                                        </div>
                                    </div>

                                    <div className="list-header with-ckeck check-yellow-color-2">
                                        <div className="marker"></div>
                                        <div className="title-wrap">
                                            <h5 className="heading heading-h5 text-white">Landscape Architecture</h5>
                                        </div>
                                    </div>

                                    <div className="list-header with-ckeck check-yellow-color-2">
                                        <div className="marker"></div>
                                        <div className="title-wrap">
                                            <h5 className="heading heading-h5 text-white">UI/UX designs</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="bk-stratagy-thumb bg_image--25 ptb--180 h-100">

                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    themeMainFontColor: state.styleConfig.themColorCss,
    styleConfig: state.styleConfig
  }
}

export default connect(mapStateToProps)(SectionFive);