import api from '../api/'
import { async } from 'q';
import axios from 'axios';
import history from '../history';

export const setSection = (activeSection) => {
  return dispatch => {
    dispatch({ type: 'SET_SECTION', payload: activeSection })
  }
}

export const setSubscribedUser = (data) => {
  return async function(dispatch, getState) {
    if(data != ""){
      let index = data.indexOf('@')
      if(index != -1){
        await api.post(
          '/saveUserSubscribed',
          {
            email: data.toString()
          }
        )
        dispatch({ type: 'SET_SUBSCRIBE_USER', action: getState().subscribedUser.email })
      }
    }
  }
}

export const setNewSection = (section) => {
  return {
    type: "SET_SECTION",
    pauload: section
  }
}

export const logout = () => {
  api.post('/logout')
    .then(res => {
      localStorage.clear()
      console.log("user logged out")
    }).catch(err => {
      console.error({ message: "error when logging out", error: err})
    })
}

export const loginUser = (data, history) => (dispatch) => {
  // dispatch({ type: 'LOADING_UI' })
  api.post('/login', data)
    .then((res) => {
      localStorage.setItem('FBIdToken', `Baarer ${res.data.stsTokenManager.accessToken}`)
      // dispatch({ type: 'LOADING_UI' })
      dispatch(getUserData(res.data))
      history.push('/admin/index')
      dispatch({ type: 'HIDE_LANDING_HEADER' })
    }).catch(err => {
      console.log({message: "error when login", err})
    })
}

export const getOffers = () => {
  return async function(dispatch, getState){
    const currentOffers = getState().offer.offers
    if(currentOffers.length <= 0){
      const response = await api.get('/getJobs')
      dispatch({ type: 'GET_OFFERS', payload: response.data })
    }
  }
}

export const getOfferDetail = (id) => {
  return async function(dispatch, getState){
    const response = await api.get(`getOfferDetail/?id=${id}`)
    dispatch({ type: 'UPDATE_OFFER', payload: response.data})
  }
}

export const getSelectedOffer = () => {
  return async function(dispatch, getState) {
    dispatch({ type: 'GET_SELECTED_OFFER'})
  }
}

export const setSelectedOffer = (offer, history) => {
  return async function(dispatch, getState) {
    dispatch({ type: 'SET_SELECTED_OFFER', payload: offer.id})
    const response = await api.get(`getOfferDetail/?id=${offer.id}`)
    dispatch({ type: 'UPDATE_OFFER', payload: response.data})
  }
}

export const signup = (data, history) => (dispatch) => {
  api.post('signup', data)
  .then(res => {
    localStorage.setItem('FBIdToken', `Baarer ${res.data.token}`)
    dispatch({ type: 'SET_USER_CREDENTIALS', payload: data })
    history.push('/dashboard')
  }).catch(err => {
    console.error(err)
  })
}

export const getUserData = (userData) => async(dispatch) => {
  // TODO fix axios.defaults to set headers
  // axios.defaults.common['Authorization'] = `Baarer ${res.data.stsTokenManager.accessToken}`
  api.get(`/users?userId=${userData.uid}`, {
    headers: {
      'Authorization': `Baarer ${userData.stsTokenManager.accessToken}`
    }
  })
    .then((res) => {
      return dispatch({ type: 'SET_USER_CREDENTIALS', payload: res.data.credentials })
    }).catch(err => {
      return false
    })
}
