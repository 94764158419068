import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getSelectedOffer } from '../../actions/offerActions'

import {
  Row,
  Col
} from "reactstrap";

const OfferDetail = (props) => {
  const dispatch = useDispatch()
  const selectedOffer = useSelector(state => state.offer.selectedOffer) || null
  useEffect(() => {
    getSelectedOffer()
  }, [])
  if(!selectedOffer.details){
    return(
      <div>
        <h1>Error Loading Data</h1>
      </div>
    )
  }
  return(
    <div>
      <div>
        <h1>Descripción</h1>
        {selectedOffer.details.description}
      </div>
      <div className="pt-5">
        <h1>Habilidades Necesarias</h1>
        <Row className="row hr-skill-list">
          <Col lg="2" className="custom-control custom-control-alternative custom-checkbox mb-3">
            <input
              className="custom-control-input"
              defaultChecked
              id="customCheck6"
              type="checkbox"
              disabled="true"
            />
            <label className="custom-control-label" htmlFor="customCheck6">
              Liderazgo
            </label>
          </Col>
          <Col lg="2" className="custom-control custom-control-alternative custom-checkbox mb-3">
            <input
              className="custom-control-input"
              defaultChecked
              id="customCheck6"
              type="checkbox"
              disabled="true"
            />
            <label className="custom-control-label" htmlFor="customCheck6">
              Liderazgo
            </label>
          </Col>
          <Col lg="2" className="custom-control custom-control-alternative custom-checkbox mb-3">
            <input
              className="custom-control-input"
              defaultChecked
              id="customCheck6"
              type="checkbox"
              disabled="true"
            />
            <label className="custom-control-label" htmlFor="customCheck6">
              Data Analytics
            </label>
          </Col>
          <Col lg="2" className="custom-control custom-control-alternative custom-checkbox mb-3">
            <input
              className="custom-control-input"
              defaultChecked
              id="customCheck6"
              type="checkbox"
              disabled="true"
            />
            <label className="custom-control-label" htmlFor="customCheck6">
              Inglés Avanzado
            </label>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default OfferDetail